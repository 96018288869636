import React from 'react';
import { Link } from 'react-router-dom';
import {Metadata} from "../models/metadata";

const MonkezCard = (props: {
  item: Metadata,
}) => {
  return (
    <>
      <div className="card bordered">
        <figure>
          <Link to={`/${props.item.id}`}>
            <img alt={props.item.name} src={props.item.image} className="min-h-16" />
          </Link>
        </figure>
        <div className="card-body p-0">
          <h2 className="card-title mb-0 p-4">{props.item.name}</h2>
          <div tabIndex={0} className="collapse border-t-2 border-gray-600 collapse-arrow">
            <div className="collapse-title text-lg font-medium">
              Description
            </div>
            <div className="collapse-content">
              <p>
                {props.item.description}
              </p>
            </div>
          </div>
          <div tabIndex={1} className="collapse border-t-2 border-gray-600 collapse-arrow">
            <div className="collapse-title text-lg font-medium">
              Attributes
            </div>
            <div className="collapse-content">
              <ul className={"w-full"}>
                {props.item.attributes.map((v,index) => (
                  <li key={index} className={"flex border-t border-gray-400 p-2"}>
                    <span className={"w-1/2"}>{v.trait_type}</span>
                    <span>{v.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default MonkezCard;
