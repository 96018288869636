import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useConnection} from "@solana/wallet-adapter-react";
import {PublicKey} from "@solana/web3.js";

import monkez from '../assets/monkez.json';
import {getHolderSolscan, getTokens, Metadata} from "../models/metadata";
import NFTCard from "../components/NFTCard";
import {classNames} from "../utils/utils";

const NFTView = () => {
  let params = useParams();
  const {connection} = useConnection();
  const id = parseInt(params.id as string);
  const metadata : Metadata = monkez.find((m) => m.id === id) as Metadata;
  const hasNext : boolean = monkez.length > (id + 1);
  const hasPrev : boolean = id !== 0;

  const [tokens, setTokens] = useState<PublicKey[]>([]);
  const [paginatedTokens, setPaginatedTokens] = useState<PublicKey[]>([]);
  const [tabActive, setTabActive] = useState(0);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function loadNFTs() {
      let ownerKey = new PublicKey(metadata.holder);
      const solScanholders = await getHolderSolscan(metadata.mint.toString());
      if (Array.isArray(solScanholders) && solScanholders.length > 0) {
        ownerKey = new PublicKey(solScanholders[0]);
      }
      const res = await getTokens(connection, ownerKey);
      const filteredTokens = res.filter((v) => v.toString() !== metadata.mint.toString());
      console.log('get nfts from owner', filteredTokens.length);
      setTokens(filteredTokens);
      setPaginatedTokens(filteredTokens.splice(0, 18));
    }
    setPaginatedTokens([]);
    setLoading(true);
    loadNFTs()
      .then(() => console.log('data loaded'))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, [connection, metadata]);

  const loadMore = () => {
    const currentLength = paginatedTokens.length;
    const newTokens = paginatedTokens.concat(tokens.splice(currentLength, 18));
    setPaginatedTokens(newTokens);
  }

  return (
    <>
      <div className="flex justify-between my-4">
        <Link
          className={"btn btn-xs md:btn-sm lg:btn-md"}
          to={hasPrev ? `/${id - 1}` : '#'}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
          {hasPrev && <span>Flagz#{id - 1}</span>}
        </Link>
        <Link
          className={"btn btn-xs md:btn-sm lg:btn-md"}
          to={hasNext ? `/${id + 1}` : '#'}>
          {hasNext && <span>Flagz#{id + 1}</span>}
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </Link>
      </div>
      <div className="flex flex-col md:flex-row gap-8 mt-6">
        <div className="">
          <img src={metadata.image} alt={metadata.name} className={"mx-auto h-96 rounded-box"}/>
        </div>
        <div className={"md:flex-shrink md:w-3/4"}>
          <h2 className={"text-2xl font-semibold"}>{metadata.name}</h2>
          <p className="text-base font-normal">
            {metadata.description}
          </p>
          <div className="grid grid-cols-3 gap-4 my-4">
            {metadata.attributes.map((attr, index) => (
              <div key={index} className={"card shadow"}>
                <div className="card-body p-4">
                  <h2 className="card-title">{attr.trait_type}</h2>
                  <p>{attr.value}</p>
                </div>
              </div>
            ))}
          </div>
          <button
            className={"mt-2 btn btn-sm btn-outline btn-info"}
            onClick={() => navigator.clipboard.writeText(window.location.toString().split('#')[0])}
          >
            Share this flagz
          </button>
          <div className="mt-2 tabs tabs-boxed">
            <button
              onClick={() => setTabActive(0)}
              className={classNames(tabActive === 0 ? 'tab-active' : '', 'tab')}>
              NFTs
            </button>
            <button
              onClick={() => setTabActive(1)}
              className={classNames(tabActive === 1 ? 'tab-active' : '', 'tab')}>
              Islands
            </button>
          </div>
          <div className="mt-2 tabs-content">
            <div className={classNames(tabActive === 0 ? '' : 'hidden', 'tab-content')}>
              <div className="flex justify-center items-center">
                <ul className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 place-items-center place-content-center">
                  {paginatedTokens.map((nft, idx) => (
                    <li key={idx}>
                      <NFTCard mintKey={nft} />
                    </li>
                  ))}
                </ul>
              </div>
              {isLoading &&
                <div className="flex justify-center items-center my-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-neutral"/>
                </div>
              }
              {!isLoading && paginatedTokens.length < tokens.length &&
                <button onClick={() => loadMore()} className="btn btn-sm btn-primary my-4">Load More</button>
              }
            </div>
            <div className={classNames(tabActive === 1 ? '' : 'hidden', 'tab-content')}>
              <p>Cooming Soon</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NFTView;
