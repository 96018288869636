import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { useCollection } from "../hooks/useCollection";
import { Collection } from "../providers/CollectionProvider";

export interface SelectOption {
  value: string;
  label: string;
}

export const CollectionSelector = () => {
  // const [options, setOptions] = useState<SelectOption[]>([]);
  const {
    availableCollections,
    collections,
    setCollections,
  } = useCollection();

  const removeCollection = (collection: Collection) => {
    const newCollections = collections
      .filter((col) => col.symbol !== collection.symbol);
    setCollections(newCollections);
    localStorage.setItem('flag_collections', JSON.stringify(newCollections));
  }

  return (
    <>
      <div className="flex flex-wrap my-2 gap-2 w-full">
        {collections.map((coll, index) => (
          <div>
            <button
              onClick={() => removeCollection(coll)}
              className="btn btn-xs"
              key={index}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   className="inline-block w-4 h-4 mr-1 stroke-current">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
              <span className={"inline-flex"}>{coll.name}</span>
            </button>
          </div>
        ))}
      </div>
      <Autocomplete
        multiple
        id="collections"
        options={availableCollections}
        getOptionLabel={(option) => option.name}
        value={collections}
        onChange={(event, newValue) => {
          setCollections(newValue);
          localStorage.setItem('flag_collections', JSON.stringify(newValue));
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <div ref={params.InputProps.ref} className={"form-control"}>
            <input
              {...params.inputProps}
              placeholder={"Find collections"}
              type="text"
              className={"input input-lg input-bordered"}/>
          </div>
        )}
      />
    </>
  )
}
