import React from 'react';
import { TokensProvider } from '../providers/TokensProvider';
import TokensForm from '../components/tokens/TokensForm';
import TokensList from '../components/tokens/TokensList';

const TokensListView = () => {
  return (
    <TokensProvider>
      <div className="sm:pt-4 pb-10 sm:pb-14 flex flex-wrap items-center">
        <div className="w-full flex-none text-center xl:w-auto xl:flex-auto mt-10">
          <h1
            className="font-display text-white text-3xl leading-9 font-semibold sm:text-4xl sm:leading-10">
            Tokens in sale
          </h1>
        </div>
      </div>
      <div className="flex justify-end mb-4">
        <TokensForm/>
      </div>
      <TokensList/>
    </TokensProvider>
  );
};

export default TokensListView;
