import React from "react";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";

export const ConnectButton = (props: {
  label: string,
}) => {
  return (
    <>
      <WalletMultiButton className={"ml-4 btn"} children={props.label} />
    </>
  )
};
