import React, { useState } from 'react';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { useTokens } from '../../hooks/useTokens';
import { Tokens } from '../../models/tokens';

const TokensForm = () => {
  const wallet = useAnchorWallet();

  const [label, setLabel] = useState('');
  const [address, setAddress] = useState('');
  const [twitter, setTwitter] = useState('');
  const [discord, setDiscord] = useState('');
  const [launch, setLaunch] = useState('');
  const [supply, setSupply] = useState(1);

  const {
    addTokens,
    openModal,
    setOpenModal,
  } = useTokens();

  const submit = async () => {
    if (!wallet) {
      return;
    }
    const data: Tokens = {
      label,
      address,
      twitter,
      discord,
      launch,
      supply,
      owner: wallet.publicKey.toString(),
    };
    await addTokens(data);
    setOpenModal(false);
  };

  return (
    <>
      <button
        onClick={() => setOpenModal(true)}
        className={'btn btn-primary'}>
        Submit Token
      </button>
      <input
        checked={openModal}
        onChange={() => setOpenModal((prev) => !prev)}
        type="checkbox"
        className={'modal-toggle'}/>
      <div className="modal">
        <div className="modal-box">
          {wallet ? (
            <div className={'form'}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Token Label</span>
                </label>
                <input
                  onChange={(e) => setLabel(e.target.value)}
                  type="text"
                  placeholder="e.g. Flag Monkez"
                  className="input input-bordered"/>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Token Address</span>
                </label>
                <input
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  placeholder="e.g. GUhq...Ay2r"
                  className="input input-bordered"/>
              </div>
              <div className="flex justify-between">
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Supply</span>
                  </label>
                  <input
                    onChange={(e) => setSupply(parseInt(e.target.value))}
                    type="number"
                    placeholder="e.g. 9999"
                    className="input input-bordered"/>
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Launch Date</span>
                  </label>
                  <input
                    onChange={(e) => setLaunch(e.target.value)}
                    type="datetime-local"
                    className="input input-bordered"/>
                </div>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Discord</span>
                </label>
                <input
                  onChange={(e) => setDiscord(e.target.value)}
                  type="url"
                  className="input input-bordered"/>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Twitter</span>
                </label>
                <input
                  onChange={(e) => setTwitter(e.target.value)}
                  type="url"
                  className="input input-bordered"/>
              </div>
            </div>
          ) : (
            <div className="alert alert-error">
              <div className="flex-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-6 h-6 mx-2 stroke-current">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"/>
                </svg>
                <label>Please connect to a wallet first!</label>
              </div>
            </div>
          )}
          <div className="modal-action">
            {wallet && (<button onClick={() => submit()} className="btn btn-primary">Submit</button>)}
            <button onClick={() => setOpenModal(false)} className="btn">Close</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TokensForm;
