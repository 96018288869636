import React, {useEffect, useState} from 'react';
import {PublicKey} from "@solana/web3.js";
import {getMetadata, JSONMetadata} from '../models/metadata';
import {useConnection} from "@solana/wallet-adapter-react";

const NFTCard = (props: {
  mintKey: PublicKey,
  // meta: JSONMetadata,
}) => {
  const {connection} = useConnection();
  const [meta, setMeta] = useState<JSONMetadata>();

  useEffect(() => {
    let isMounted = true;
    getMetadata(connection, props.mintKey)
      .then((res) => {
        if (isMounted) {
          setMeta(res);
        }
      })
      .catch((e) => console.log(e));
    return () => { isMounted = false };
  }, [connection, props.mintKey]);
  return (
    <>
      <div className="card bordered h-36">
        <figure className={"h-full"}>
          <a href={`#modal-${props.mintKey}`}>
            <img alt={meta?.name} src={meta?.image} className={"h-full"} />
          </a>
        </figure>
      </div>
      <div id={`modal-${props.mintKey}`} className="modal">
        <div className="modal-box">
          <div className="flex justify-between">
            <h2 className={"text-center text-center text-2xl font-semibold mb-4 w-full"}>{meta?.name}</h2>
            <a href="#" className="btn btn-sm btn-circle">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   className="inline-block w-4 h-4 stroke-current md:w-6 md:h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>
          <img src={meta?.image} alt={meta?.name} className={"object-cover object-center w-48 mx-auto rounded"} />
          <p className="text-base font-normal my-4 text-center">
            {meta?.description}
          </p>
          <h3 className={"text-center text-xl font-semibold mb-4"}>Attributes</h3>
          {meta?.attributes && Array.isArray(meta?.attributes) &&
          <p className={"text-base font-normal my-4 text-center"}>
            {meta?.attributes.map((v) => v.value).join(', ')}
          </p>
          }
          <div className="modal-action">
            {meta?.name !== 'Unknown' &&
              <div className={"flex justify-center flex-col md:flex-row gap-4 w-full"}>
                <a
                  href={`https://magiceden.io/item-details/${props.mintKey}`}
                  target="_blank"
                  className="btn btn-primary btn-sm"
                  rel={"noreferrer"}>
                  Buy on MagicEden
                </a>
                <a
                  href={`https://exchange.art/single/${props.mintKey}`}
                  target="_blank"
                  className="btn btn-sm"
                  rel={"noreferrer"}>
                Buy on Exchange
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default NFTCard;
