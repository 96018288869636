import React, { Dispatch, SetStateAction, useState } from "react";
import axios from "axios";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useProjects } from "../hooks/useProjects";

interface ProjectFormProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

const ProjectForm = ({openModal, setOpenModal}: ProjectFormProps) => {
  const wallet = useAnchorWallet();

  const { fetchProjects }= useProjects();

  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [file, setFile] = useState<File | undefined>();
  const [price, setPrice] = useState(0.03);
  const [supply, setSupply] = useState(9999);
  const [date, setDate] = useState('');
  const [website, setWebsite] = useState('');
  const [discord, setDiscord] = useState('');
  const [twitter, setTwitter] = useState('');
  const [maxClaims, setMaxClaims] = useState(10);

  const reset = () => {
    setName('');
    setFile(undefined);
    setPrice(0.03);
    setSupply(9999);
    setDate('');
    setWebsite('');
    setDiscord('');
    setTwitter('');
    setMaxClaims(10);
  }

  const submit = async () => {
    setLoading(true);
    const fd = new FormData();
    if (file) {
      fd.append('image', file, file.name);
    }
    fd.append('name', name);
    fd.append('price', price.toString());
    fd.append('supply', supply.toString());
    fd.append('date', date);
    fd.append('website', website);
    fd.append('discord', discord);
    fd.append('twitter', twitter);
    fd.append('owner', wallet?.publicKey.toString() as string);
    fd.append('max_claims', maxClaims.toString());
    try {
      await axios.post('/projects/', fd);
      reset();
      setOpenModal(false);
      await fetchProjects();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <input
        checked={openModal}
        onChange={() => setOpenModal((prev) => !prev)}
        type="checkbox"
        className={"modal-toggle"}/>
      <div className="modal">
        <div className="modal-box">
          <div className={"form"}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Project Name</span>
              </label>
              <input
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="e.g. Flag Monkez"
                className="input input-bordered"/>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Project Image</span>
              </label>
              <input
                onChange={(e) => setFile(e.target.files ? e.target.files[0] : undefined)}
                type="file"
                className="input pl-0"/>
            </div>
            <div className="flex justify-between">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Mint Price</span>
                </label>
                <label className="input-group input-group-md">
                  <input
                    onChange={(e) => setPrice(parseFloat(e.target.value))}
                    type="number"
                    value={price}
                    className="w-full input input-bordered"/>
                  <span>SOL</span>
                </label>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Mint Supply</span>
                </label>
                <input
                  onChange={(e) => setSupply(parseInt(e.target.value))}
                  type="number"
                  placeholder="e.g. 9999"
                  className="input input-bordered"/>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Max Claims</span>
                </label>
                <input
                  onChange={(e) => setMaxClaims(parseInt(e.target.value))}
                  type="number"
                  value={maxClaims}
                  className="input input-bordered"/>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Mint Started</span>
                </label>
                <input
                  onChange={(e) => setDate(e.target.value)}
                  type="datetime-local"
                  className="input input-bordered"/>
              </div>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Website</span>
              </label>
              <input
                onChange={(e) => setWebsite(e.target.value)}
                type="url"
                className="input input-bordered"/>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Discord</span>
              </label>
              <input
                onChange={(e) => setDiscord(e.target.value)}
                type="url"
                className="input input-bordered"/>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Twitter</span>
              </label>
              <input
                onChange={(e) => setTwitter(e.target.value)}
                type="url"
                className="input input-bordered"/>
            </div>
          </div>
          <div className="modal-action">
            <button disabled={isLoading} onClick={() => submit()} className="btn btn-primary">Submit</button>
            <button disabled={isLoading} onClick={() => setOpenModal(false)} className="btn">Close</button>
          </div>
        </div>
      </div>
    </>
  )
};

export default ProjectForm;
