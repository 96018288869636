import { createContext, Dispatch, FC, SetStateAction, useState } from 'react';
import { Tokens } from '../models/tokens';

export const TokensContext = createContext<[Tokens[], Dispatch<SetStateAction<Tokens[]>>] | null>(null);

export const TokensProvider: FC = ({children}) => {
  const [tokens, setTokens] = useState<Tokens[]>([]);
  return (
    <TokensContext.Provider value={[tokens, setTokens]}>
      {children}
    </TokensContext.Provider>
  );
};
