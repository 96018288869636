import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Collection, CollectionContext } from "../providers/CollectionProvider";
import axios from "axios";

const getMagicEdenColls = async () => {
  const resp = await axios.get<{ collections: Collection[] }>('https://api-mainnet.magiceden.io/all_collections');
  return resp.data.collections;
}

export const useCollection = () => {
  const context = useContext(CollectionContext) as [Collection[], Dispatch<SetStateAction<Collection[]>>];
  const [availableCollections, setAvailableCollections] = useState<Collection[]>([]);

  useEffect(() => {
    getMagicEdenColls()
      .then((colls) => {
        setAvailableCollections(colls);
      })
      .catch((e) => console.log(e));
  }, [setAvailableCollections]);

  return {
    availableCollections,
    collections: context[0],
    setCollections: context[1],
  };
}
