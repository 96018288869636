import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useTokens } from '../../hooks/useTokens';
import { shortenAddress } from '../../utils/utils';

const TokensList = () => {
  const {
    tokensList,
    fetchTokens,
  } = useTokens();

  useEffect(() => {
    fetchTokens()
      .then(() => console.log('tokens fetched'));
  }, [fetchTokens]);

  return (
    <div className="overflow-x-auto w-full my-4">
      <table className="table table-non-sticky w-full">
        <thead>
        <tr>
          <th>Label</th>
          <th>Address</th>
          <th>Links</th>
          <th>Launch Date</th>
          <th>Supply</th>
        </tr>
        </thead>
        <tbody>
        {tokensList && tokensList.map((token, index) => (
          <tr key={index}>
            <td>
              <div className="flex items-center space-x-3">
                <div>
                  <div className="flex flex-col md:flex-row text-xs md:text-base font-bold">
                    <span>{token.label}</span>
                  </div>
                </div>
              </div>
            </td>
            <td>{shortenAddress(token.address)}</td>
            <td>
              <div className="flex items-center space-x-2">
                {token.twitter && (
                  <a href={token.twitter} target={'_blank'} rel={'noreferrer'}>
                    <svg
                      className="h-4 w-4 md:h-6 md:w-6 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24">
                      <path
                        d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
                      />
                    </svg>
                  </a>
                )}
                {token.discord && (
                  <a href={token.discord} target={'_blank'} rel={'noreferrer'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-4 w-4 md:h-6 md:w-6"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                    </svg>
                  </a>
                )}
              </div>
            </td>
            <td>{token.launch ? dayjs(token.launch).fromNow() : 'TBA'}</td>
            <td>{token.supply}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default TokensList;
