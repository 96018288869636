import React, { useEffect, useState } from 'react';
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import { Connection, ParsedConfirmedTransaction, PublicKey } from "@solana/web3.js";
import dayjs from "dayjs";

interface SPLTokenInstruction {
  parsed: {
    info: {
      account: string;
      authority: string;
      destination: string;
      lamports: number;
      mint: string;
      source: string;
      tokenAmount: {
        amount: string;
        decimals: number;
        uiAmount: number;
        uiAmountString: string;
      };
    };
    type: string;
  };
  program: string;
  programId: PublicKey;
}

const TransactionDetail = (props: {
  transaction: ParsedConfirmedTransaction;
}) => {
  // const wallet = useAnchorWallet();
  const [platform, setPlatform] = useState('');
  const [date, setDate] = useState<string>();
  // const [type, setType] = useState<number>(0);
  // const [asset, setAsset] = useState('');
  // const [amount, setAmount] = useState(0);
  const [destination, setDestination] = useState('');

  useEffect(() => {
    const unixTime = props.transaction.blockTime ? props.transaction.blockTime * 1000 : null;
    if (unixTime) {
      try {
        setDate(dayjs(unixTime).format('DD/MM/YYYY'));
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const MAGICEDEN_ID = 'MEisE1HzehtrDpAAT8PnLHjpSSkRYakotTuJRPjTpo8';
      const METAPLEX_ID = 'p1exdMJcjVao65QdewkaZRUnU6VPSXhus9n2GzWfh98';
      const SPLTOKEN_ID = 'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL';
      const TOKEN_PROGRAM_ID = 'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA';

      const instructions = props.transaction.transaction.message.instructions;
      const programs = instructions.map((tr) => tr.programId.toString());
      if (programs.includes(MAGICEDEN_ID)) {
        setPlatform('MagicEden');
        const innerInstructions = props.transaction.meta?.innerInstructions;
        if (innerInstructions && innerInstructions.length > 0) {
          const instruction = innerInstructions[0].instructions[0] as SPLTokenInstruction;
          setDestination(instruction.parsed.info.destination);
          // setAmount(instruction.parsed.info.lamports / 1000);
        }
      } else if (programs.includes(METAPLEX_ID)) {
        setPlatform('Metaplex');
        const innerInstructions = props.transaction.meta?.innerInstructions;
        if (innerInstructions && innerInstructions.length > 0) {
          const instruction = innerInstructions[0].instructions[0] as SPLTokenInstruction;
          if (instruction.program === 'system') {
            // setAmount(instruction.parsed.info.lamports / 1000);
          } else {
            // setAmount(0);
          }
        }
      } else if (programs.includes(TOKEN_PROGRAM_ID)) {
        const splTokenInstruction = instructions.find((v) => v.programId.toString() === SPLTOKEN_ID) as SPLTokenInstruction;
        if (splTokenInstruction) {
          setPlatform('SPL Token');
          setDestination(splTokenInstruction.parsed.info.account);
          // setAmount(1);
        } else {
          const splTokenInstruction = instructions[0] as SPLTokenInstruction;
          setPlatform('SPL Token');
          setDestination(splTokenInstruction.parsed.info.destination);
          // setAmount(1);
        }
      } else {
        console.log(props.transaction.transaction.signatures[0], programs);
      }
    } catch (e) {
      // console.log(e);
    }

  }, [props.transaction]);

  return (
    <tr>
      <td>{platform}</td>
      <td>{date}</td>
      <td>{destination}</td>
      <td>
        <a className={"btn btn-sm btn-outline"} rel="noreferrer" target={"_blank"}
           href={`https://solscan.io/tx/${props.transaction.transaction.signatures[0]}`}>
          Check on Solscan
        </a>
      </td>
    </tr>
  )
}


const TransactionList = () => {
  const wallet = useAnchorWallet();
  const {connection} = useConnection();

  const [transactions, setTransactions] = useState<ParsedConfirmedTransaction[]>([]);

  useEffect(() => {
    setTransactions([]);

    async function getHistory(connection: Connection, publicKey: PublicKey) {
      const signs = await connection.getConfirmedSignaturesForAddress2(publicKey, {
        limit: 10,
      });
      const trx = await connection.getParsedConfirmedTransactions(signs.map((sign) => sign.signature));

      function notempty<ParsedConfirmedTransaction>(value: ParsedConfirmedTransaction | null): value is ParsedConfirmedTransaction {
        return value !== null;
      }

      console.log(trx.filter(notempty));
      setTransactions(trx.filter(notempty));
    }

    if (wallet) {
      getHistory(connection, wallet.publicKey)
        .then(() => console.log('data loaded'))
        .catch((e) => console.log(e));
    }
  }, [connection, wallet]);

  return (
    <div className="overflow-x-auto">
      <table className="table w-full">
        <thead>
        <tr>
          <th>Platform</th>
          <th>Date</th>
          <th>From/To</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {transactions.map((transaction, index) => (
          <TransactionDetail transaction={transaction} key={index}/>
        ))}
        </tbody>
      </table>
    </div>
  )
};

export const Dashboard = () => {
  return (
    <>
      <h1 className={"my-4 text-2xl font-bold"}>Dashboard</h1>
      <TransactionList/>
    </>
  );
}
