import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom"
import {shortenAddress} from "../utils/utils";
import {AnchorWallet} from "@solana/wallet-adapter-react/src/useAnchorWallet";
import {useAnchorWallet, useConnection, useWallet} from "@solana/wallet-adapter-react";
import {LAMPORTS_PER_SOL} from '@solana/web3.js';

export const UserDropdown = (props: {
  wallet: AnchorWallet,
}) => {
  const {disconnect} = useWallet();
  const {connection} = useConnection();
  const [balance, setBalance] = useState(0);
  const wallet = useAnchorWallet() as AnchorWallet;
  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, connection]);

  return (
    <div className="dropdown dropdown-end">
      <div tabIndex={0} className="ml-4 my-1 btn btn-primary">
        {shortenAddress(props.wallet.publicKey.toString(), 4)} ({(balance || 0).toLocaleString()} SOL)
      </div>
      <ul tabIndex={0} className="p-2 shadow menu dropdown-content rounded-box w-52 bg-base-100 text-primary">
        <li>
          <Link to={'/dashboard'}>Dashboard</Link>
        </li>
        <li>
          <a onClick={() => disconnect()} href="#">Disconnect</a>
        </li>
      </ul>
    </div>
  )
}
