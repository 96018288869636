import React, {useEffect, useState} from 'react';
import {useConnection} from '@solana/wallet-adapter-react';

import monkez from '../assets/monkez.json';
import MonkezList from "../components/MonkezList";
import {Metadata} from '../models/metadata';

const Home = () => {
  const {connection} = useConnection();
  const [metadatas, setMetadatas] = useState<Metadata[]>([]);

  useEffect(() => {
    setMetadatas(monkez as Metadata[]);
  }, [connection, metadatas]);

  return (
    <div>
      <MonkezList items={metadatas} />
    </div>
  );
};

export default Home;
