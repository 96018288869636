import React from 'react';


const AlphaHunterView = () => {
  const hunters = [
    '5000#3790',
    'zxcv#7179',
    'SolMon#5376',
    'SolaMigo#5994',
    'Sol.Shiesty#4929',
    'sleep#3125',
    '?/#1593',
    'rrell#3350',
    'Kryll#2797',
    'KalKo#5474',
    'JayKhun#7083',
    'Dango#3730',
    'Buxor#0001',
    'bandito#6008',
    '90#0009',
    'Nabong#8236',
    'Lluoar#2099',
    'FAST#0001',
    '2004.sol⭐#6571',
    'warpath#4367',
    'Vel#0944',
    'Tipsy#0222',
    'Ramon#0002',
    'p1x3L#8112',
    'Hoch#5181',
    'eggie#1001',
    'Bryan#0005',
    'achimura#0001',
    'wings#3655',
    'Retaliate#3337',
    'Purchasable.sol | .near#4177',
    'Daddy 2222#6771',
    'ajmnlt.sol#0001',
    'RizFlagz#1000',
    'Abacus#8601',
  ];
  return (
    <div className="overflow-x-auto w-full my-4">
      <table className="table table-non-sticky w-full">
        <thead>
        <tr>
          <th>No</th>
          <th>Discord Tag</th>
          <th>Role</th>
        </tr>
        </thead>
        <tbody>
        {hunters.map((hunter, i) => (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{hunter}</td>
            <td>Alpha Hunter</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default AlphaHunterView;
