import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import dayjs from "dayjs";
import { LinkIcon, ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/solid";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Snackbar } from "@material-ui/core";
import { useProjects } from "../hooks/useProjects";
import { Alert, Color } from "@material-ui/lab";
import { AxiosError } from "axios";
import { Project } from "../models/project";


const ProjectList = () => {
  const wallet = useAnchorWallet();

  const [page, setPage] = useState(1);
  const [openClaim, setOpenClaim] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState<Color>('success');
  const [message, setMessage] = useState('');
  const [id, setId] = useState<number>();
  const [discord, setDiscord] = useState('');
  const [address, setAddress] = useState('');

  const {
    fetchProjects,
    projects,
    upVote,
    downVote,
    claim,
  } = useProjects();

  const openClaimForm = (id: number) => {
    setId(id);
    setOpenClaim(true);
    setOpenAlert(false);
    setSeverity('success');
    setMessage('');
  }

  const closeClaim = () => {
    setId(undefined);
    setOpenClaim(false);
    setOpenAlert(false);
    setSeverity('success');
    setMessage('');
  }

  const vote = async (type: string, id: number) => {
    if (!wallet) {
      setMessage('connect to a wallet to vote');
      setSeverity('error');
      setOpenAlert(true);
      return;
    }
    const address = wallet ? wallet.publicKey.toString() : '';
    try {
      if (type === 'up') {
        await upVote(id, address);
      } else {
        await downVote(id, address);
      }
    } catch (e) {
      const err = e as AxiosError;
      if (err.response) {
        setMessage(err.response.data.message);
        setSeverity('error');
        setOpenAlert(true);
      }
    }
  }

  const submit = async () => {
    if (!wallet) {
      setMessage('connect to a wallet to continue');
      setSeverity('error');
      setOpenAlert(true);
      return;
    }

    try {
      await claim(id as number, address, wallet.publicKey.toString(), discord);
      closeClaim();
      setMessage('claim project succeed');
      setSeverity('success');
      setOpenAlert(true);
    } catch (e) {
      setMessage('make sure you have flagz nft to claim this');
      setSeverity('error');
      setOpenAlert(true);
    }
  }

  useEffect(() => {
    fetchProjects().then(() => console.log('data fetched from project lists'));
  }, [fetchProjects]);

  const hasClaimed = (project: Project) => {
    if (!wallet) {
      return false;
    }
    return project.claims.includes(wallet.publicKey.toString());
  }

  return (
    <>
      <input
        checked={openClaim}
        onChange={closeClaim}
        type="checkbox"
        className={"modal-toggle"}/>
      <div className="modal">
        <div className="modal-box">
          <div className={"form"}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Discord ID</span>
              </label>
              <input
                onChange={(e) => setDiscord(e.target.value)}
                type="text"
                placeholder="e.g. @flagz"
                className="input input-bordered"/>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Wallet Address</span>
                <button onClick={() => setAddress(wallet ? wallet.publicKey.toString() : '')} className="btn btn-xs">
                  Use wallet address
                </button>
              </label>
              <input
                onChange={(e) => setAddress(e.target.value)}
                type="text"
                value={address}
                className="input input-bordered"/>
            </div>
          </div>
          <div className="modal-action">
            <button disabled={!discord} onClick={() => submit()} className="btn btn-primary">Submit</button>
            <button onClick={closeClaim} className="btn">Close</button>
          </div>
        </div>
      </div>
      <Snackbar
        open={openAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <div className="overflow-x-auto">
        <table className="table table-non-sticky w-full">
          <thead>
          <tr>
            <th>Name</th>
            <th>Links</th>
            <th>Mint Started</th>
            <th>Supply</th>
            <th>Price</th>
            <th>Vote</th>
          </tr>
          </thead>
          <tbody>
          {projects && projects.map((project, index) => (
            <tr key={index}>
              <td>
                <div className="flex items-center space-x-3">
                  <div className="avatar">
                    <div className="w-8 h-8 md:w-12 md:h-12 mask mask-squircle">
                      {project.image &&
                        <img src={project.image} alt={project.name}/>}
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col md:flex-row text-xs md:text-base font-bold">
                      <span>{project.name}</span>
                      <div className="md:ml-2">
                        <button
                          disabled={hasClaimed(project)}
                          onClick={() => openClaimForm(project.id)}
                          className="btn btn-xs btn-primary">
                          {hasClaimed(project) ? 'Claimed ' : 'Claim '}
                          ({project.claims.length} / {project.max_claims})
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="flex items-center space-x-2">
                  {project.website && (
                    <a href={project.website} target={"_blank"} rel={"noreferrer"}>
                      <LinkIcon className={"h-4 w-4 md:h-6 md:w-6"}/>
                    </a>
                  )}
                  {project.twitter && (
                    <a href={project.twitter} target={"_blank"} rel={"noreferrer"}>
                      <svg
                        className="h-4 w-4 md:h-6 md:w-6 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path
                          d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
                        />
                      </svg>
                    </a>
                  )}
                  {project.discord && (
                    <a href={project.discord} target={"_blank"} rel={"noreferrer"}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="h-4 w-4 md:h-6 md:w-6"
                        viewBox="0 0 16 16">
                        <path
                          d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                      </svg>
                    </a>
                  )}
                </div>
              </td>
              <td>{project.date ? dayjs(project.date).fromNow() : 'TBA'}</td>
              <td>{project.supply}</td>
              <td>{project.price} SOL</td>
              <td>
                <div className="flex space-x-2 items-center">
                  <span className="text-base">{project.vote}</span>
                  <button>
                    <ThumbUpIcon
                      onClick={() => vote('up', project.id)}
                      className={"h-4 w-4 md:h-6 md:w-6 hover:text-primary"}/>
                  </button>
                  <button>
                    <ThumbDownIcon
                      onClick={() => vote('down', project.id)}
                      className={"h-4 w-4 md:h-6 md:w-6 hover:text-primary"}/>
                  </button>
                </div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      <div className="hidden justify-center">
        <div className="btn-group">
          <button
            onClick={() => setPage((prev) => prev - 1)}
            disabled={page === 1}
            className="btn btn-sm btn-outline btn-wide">
            Previous Page
          </button>
          <button
            onClick={() => setPage((prev) => prev + 1)}
            className="btn btn-sm btn-outline btn-wide">
            Next Page
          </button>
        </div>
      </div>
    </>
  )
}

export default ProjectList;
