import React, { useEffect, useState } from "react";
import axios from 'axios';
import dayjs from 'dayjs';
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import AreaSeries, { ChartData } from "./AreaSeries";
import { UTCTimestamp } from "lightweight-charts";

interface EscrowStats {
  results: {
    floorPrice: number;
    listedCount: number;
    volumeAll: number;
    availableAttributes: {
      count: number;
      floor: number;
      attribute: {
        trait_type: string;
        value: string;
      }
    }[];
  };
}

interface ListNFTResponse {
  results: {
    id: string;
    mintAddress: string;
  }[];
}

export interface Collection {
  symbol: string;
  image: string;
  name: string;
  description: string;
}

const TradingChart = ({collection}: { collection: Collection }) => {
  const [floorPrices, setFloorPrices] = useState<ChartData[]>([]);
  const [listedCounts, setListedCounts] = useState<ChartData[]>([]);
  const [volumeAlls, setVolumeAlls] = useState<ChartData[]>([]);
  const [snipeId, setSnipeId] = useState<string>();

  const getStatistics = async (symbol: string) => {
    const resp = await axios.get<EscrowStats>(`https://api-mainnet.magiceden.io/rpc/getCollectionEscrowStats/${symbol}`);
    const {results} = resp.data;
    const now = dayjs().unix() as UTCTimestamp;
    setFloorPrices(prev => [...prev, {time: now, value: results.floorPrice / LAMPORTS_PER_SOL}]);
    setListedCounts(prev => [...prev, {time: now, value: results.listedCount}]);
    setVolumeAlls(prev => [...prev, {time: now, value: results.volumeAll / LAMPORTS_PER_SOL}]);
  }

  const getListedNFTsByQuery = async (symbol: string) => {
    const url = `https://api-mainnet.magiceden.io/rpc/getListedNFTsByQuery?q={"$limit":15,"$match":{"collectionSymbol":"${symbol}"},"$skip":0,"$sort":{"takerAmount":1,"createdAt":-1}}`
    const resp = await axios.get<ListNFTResponse>(url);
    setSnipeId(resp.data.results.map((v) => v.mintAddress)[0]);
  }

  useEffect(() => {
    getStatistics(collection.symbol).then().catch((e) => console.log('api stats error'));
    getListedNFTsByQuery(collection.symbol).then().catch((e) => console.log('api nft error'));
    const interval = setInterval(() => {
      getStatistics(collection.symbol).then().catch((e) => console.log('api stats error'));
      getListedNFTsByQuery(collection.symbol).then().catch((e) => console.log('api stats error'));
    }, 2000);

    return () => clearInterval(interval)
  }, [collection]);

  //https://jsfiddle.net/TradingView/qrb9a850/
  //https://github.com/tradingview/lightweight-charts/issues/935
  //https://github.com/tradingview/lightweight-charts/issues/560
  //https://www.magiceden.io/item-details/8rWqZQb1GBdeFRGt2Vz9PyiQ9FZzPwR6qDEXMsyGPKR7

  return (
    <div className={"my-4"}>
      <h2 className={"text-xl md:text-2xl font-normal md:font-semibold flex content-center"}>
        <img className={"inline-flex h-6 rounded-full mt-1"} src={collection.image} alt={collection.name}/>
        <span className={"inline-flex mx-2"}>{collection.name}</span>
        <a target={"_blank"} rel={"noreferrer"} href={`https://www.magiceden.io/item-details/${snipeId}`}
           className="inline-flex btn btn-xs btn-accent	mt-1">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
          </svg>
          Snipe
        </a>
      </h2>
      <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
        <div className="col-span-2 rounded-lg">
          <h3 className={"text-base md:font-semibold my-2"}>
            Floor Price
            {floorPrices &&
              <>
                :
                <span className={"ml-1 text-primary"}>
                  {floorPrices[floorPrices.length - 1]?.value}
                </span>
              </>
            }
          </h3>
          <AreaSeries height={200} data={floorPrices}/>
        </div>
        <div className="col-span-2 md:col-span-1 rounded-lg">
          <h3 className={"text-base md:font-semibold my-2"}>
            Total Volume (All)
            {volumeAlls &&
              <>
                :
                <span className={"ml-1 text-primary"}>
                  {volumeAlls[volumeAlls.length - 1]?.value}
                </span>
              </>
            }
          </h3>
          <AreaSeries height={200} data={volumeAlls}/>
        </div>
        <div className="col-span-2 md:col-span-1 rounded-lg">
          <h3 className={"text-base md:font-semibold my-2"}>
            Total Listed
            {listedCounts &&
              <>
                :
                <span className={"ml-1 text-primary"}>
                  {listedCounts[listedCounts.length - 1]?.value}
                </span>
              </>
            }
          </h3>
          <AreaSeries height={200} data={listedCounts}/>
        </div>
      </div>
    </div>
  );
}

export default TradingChart;
