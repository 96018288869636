import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import axios from 'axios';

import { Tokens } from '../models/tokens';
import { TokensContext } from '../providers/TokensProvider';


const _fetchTokens = async (): Promise<Tokens[]> => {
  const resp = await axios.get<Tokens[]>('/tokens/');
  return resp.data;
};

const _addTokens = async (data: Tokens): Promise<Tokens> => {
  const resp = await axios.post<Tokens>('/tokens/', data);
  return resp.data;
};

export const useTokens = () => {
  const [tokensList, setTokensList] = useContext(TokensContext) as [Tokens[], Dispatch<SetStateAction<Tokens[]>>];
  const [openModal, setOpenModal] = useState(false);

  const fetchTokens = useCallback(async () => {
    const data = await _fetchTokens();
    setTokensList(data);
  }, [setTokensList]);

  const addTokens = async (data: Tokens) => {
    const tokens = await _addTokens(data);
    setTokensList((prev) => ([tokens, ...prev]));
  };

  return {
    addTokens,
    fetchTokens,
    openModal,
    setOpenModal,
    tokensList,
    setTokensList,
  };
};
