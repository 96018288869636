import React, { useEffect, useRef, useState } from "react";
import {
  BusinessDay,
  createChart,
  IChartApi,
  isBusinessDay,
  ISeriesApi, isUTCTimestamp,
  TickMarkType,
  UTCTimestamp
} from "lightweight-charts";
import dayjs from "dayjs";

const DEFAULT_OPTIONS = {
  priceScale: {
    scaleMargins: {
      top: 0.3,
      bottom: 0.25
    },
    borderVisible: false
  },
  layout: {
    backgroundColor: '#000000',
    textColor: '#d1d4dc',
  },
  grid: {
    vertLines: {
      // color: "rgba(82, 83, 86, 0)"
      visible: false
    },
    horzLines: {
      // color: "rgba(82, 83, 86, 0.6)"
      color: 'rgba(42, 46, 57, 0.5)',
    }
  },
  rightPriceScale: {
    borderVisible: false,
  },
  timeScale: {
    borderVisible: false,
    barSpacing: 30,
    visible: true,
    // timeVisible: true,
    secondsVisible: true,
    tickMarkFormatter: (time: UTCTimestamp | BusinessDay, tickMarkType: TickMarkType, locale: string) => {
      if (isUTCTimestamp(time)) {
        return dayjs(time * 1000).format('HH:mm:ss');
      }
      return time;
    },
  },
  crosshair: {
    horzLine: {
      visible: false,
    },
  },
  lastValueVisible: true,
};

export interface ChartData {
  time: UTCTimestamp;
  value: number;
}

const AreaSeries = ({data, height}: { data: ChartData[], height: number }) => {
  const chartRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [chart, setChart] = useState<IChartApi>();
  const [series, setSeries] = useState<ISeriesApi<"Area">>();

  useEffect(() => {
    if (chartRef && typeof chartRef.current !== 'undefined') {
      setChart(createChart(chartRef.current, {
        ...DEFAULT_OPTIONS,
        // @ts-ignore
        width: chartRef.current.parentNode?.clientWidth,
        // @ts-ignore
        height: height,
      }));
    }
  }, [height]);

  useEffect(() => {
    if (!series) {
      setSeries(chart?.addAreaSeries({
        // topColor: "rgba(38,198,218, 0.56)",
        // bottomColor: "rgba(38,198,218, 0.04)",
        // lineColor: "rgba(38,198,218, 1)",
        // lineWidth: 2
        topColor: 'rgba(76, 175, 80, 0.56)',
        bottomColor: 'rgba(76, 175, 80, 0.04)',
        lineColor: 'rgba(76, 175, 80, 1)',
        lineWidth: 2,
      }));
    }
  }, [chart, series]);

  useEffect(() => {
    if (!chart || !series) {
      return;
    }

    series.setData(data);
  }, [chart, data, series]);

  return (
    <>
      <div ref={chartRef}/>
    </>
  )
}

export default AreaSeries;
