import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import { Project } from "../models/project";

export const ProjectsContext = createContext<[Project[], Dispatch<SetStateAction<Project[]>>]|null>(null);

export const ProjectsProvider: FC = ({children}) => {
  const [projects, setProjects] = useState<Project[]>([]);
  return (
    <ProjectsContext.Provider value={[projects, setProjects]}>
      {children}
    </ProjectsContext.Provider>
  )
}
