import { Connection } from '@solana/web3.js';
import {ParsedAccountData, PublicKey} from "@solana/web3.js";
import {TOKEN_PROGRAM_ID} from "@solana/spl-token";
import {deserialize} from "../utils/utils";
import {programs} from "@metaplex/js";
import axios from "axios";
import loadingImg from '../assets/loading.gif';

export interface Metadata extends JSONMetadata {
  id: number;
  mint: string;
  holder: string;
}

export interface JSONMetadata {
  name: string;
  image: string;
  description: string;
  attributes: {
    trait_type: string;
    value: string;
  }[];
}

export const getHolder = async (connection: Connection, mintKey: PublicKey) : Promise<string[]> => {
  const res = await connection.getParsedProgramAccounts(
    new PublicKey(TOKEN_PROGRAM_ID),
    {
      filters: [
        {
          dataSize: 165,
        },
        {
          memcmp: {
            offset: 0,
            bytes: mintKey.toBase58(),
          },
        }
      ]
    }
  )
  const accounts = res
    .map((r) => r.account.data as ParsedAccountData)
    .filter((a) => a.parsed.info.tokenAmount.uiAmount > 0);

  return accounts.map((acc) => acc.parsed.info.owner);
}

interface SolscanHolderResponse {
  success: boolean;
  data: {
    total: number;
    result: {
      address: string;
      amount: number;
      decimals: number;
      owner: string;
      rank: number;
    }[];
  };
};

export const getHolderSolscan = async (mintKey: string) : Promise<String[]> => {
  const url = `https://api.solscan.io/token/holders?token=${mintKey.toString()}&offset=0&size=20`;
  const resp = await axios.get<SolscanHolderResponse>(url);
  return resp.data.data.result.map((r) => r.owner);
} 

export const getTokens = async (connection: Connection, ownerKey: PublicKey) : Promise<PublicKey[]> => {
  const res = await connection.getTokenAccountsByOwner(ownerKey, {
    programId: TOKEN_PROGRAM_ID,
  });
  const accounts = res.value
    .map(({account}) => deserialize(account.data))
    .filter((account) => {
      try {
        if (account.mint.toString() === 'cLownTTaiiQMoyMmFjfmSGowi8HyNhCtTLFcrNKnqX6') {
          console.log(account.mint.toString(), account.amount?.toNumber());
        }
        return account.amount?.toNumber() > 0 && account.amount?.toNumber() === 1;
      } catch (e) {
        return false;
      }
    });
  return accounts.map(({mint}) => mint);
}

export const getJSONMetadata = async (uri: string) => {
  return (await axios.get<JSONMetadata>(uri)).data;
}

export const getMetadata = async (connection: Connection, mintKey: PublicKey) : Promise<JSONMetadata> => {
  const pubKey = await programs.metadata.Metadata.getPDA(mintKey);
  try {
    const metadata = await programs.metadata.Metadata.load(connection, pubKey);
    const metadataLink = metadata.data.data.uri;
    if (!metadataLink) {
      return {
        name: metadata.data.data.name,
        image: loadingImg,
        description: '',
        attributes: [],
      };
    }
    return await getJSONMetadata(metadataLink);
  } catch (e) {
    return {
      name: 'Unknown',
      image: loadingImg,
      description: '',
      attributes: [],
    }
  }
}
