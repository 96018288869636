import { createContext, Dispatch, FC, SetStateAction, useState } from "react";

export interface Collection {
  symbol: string;
  image: string;
  name: string;
  description: string;
}

export interface CollectionContextState {
  collections: Collection[];
}

export const CollectionContext = createContext<[Collection[], Dispatch<SetStateAction<Collection[]>>] | null>(null);

export const CollectionProvider: FC = ({children}) => {
  const savedCollections = JSON.parse(localStorage.getItem('flag_collections') || '[]') as Collection[];
  const [collections, setCollections] = useState<Collection[]>(savedCollections);

  return (
    <CollectionContext.Provider value={[collections, setCollections]}>
      {children}
    </CollectionContext.Provider>
  )
}
