import React, { useEffect } from "react";

import { CollectionSelector } from "../components/CollectionSelector";
import { CollectionProvider } from "../providers/CollectionProvider";
import { useCollection } from "../hooks/useCollection";
import TradingChart from "../components/TradingChart";

const TradingCharts = () => {
  const {
    collections,
  } = useCollection();

  return (
    <div>
      {collections.map((coll, index) => (
        <div key={index}>
          <TradingChart collection={coll}/>
        </div>
      ))}
    </div>
  )
}

export const SniperView = () => {
  useEffect(() => {
	  document.title = 'Floor Sniper - Flag Monkez';
  });

  return (
    <>
      <h1 className={"my-4 text-xl md:text-2xl lg:text-3xl font-bold"}>Floor Sniper</h1>
      <CollectionProvider>
        <CollectionSelector/>
        <TradingCharts/>
      </CollectionProvider>
    </>
  );
}
