import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useAnchorWallet} from "@solana/wallet-adapter-react";

import {ConnectButton} from "./ConnectButton";
import {UserDropdown} from "./UserDropdown";
import logo from '../assets/logo.png';

const Navbar = () => {
  const [theme, setTheme] = useState(sessionStorage.getItem('data-item') || 'dark');
  const wallet = useAnchorWallet();

  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-theme', theme);
    sessionStorage.setItem('data-item', theme);
  }, [theme]);

  const selectTheme = (e: React.FormEvent<HTMLSelectElement>) => {
    setTheme(e.currentTarget.value);
  }

  return (
    <div className="navbar mb-2 shadow-lg bg-neutral text-neutral-content rounded-box">
      <div className="px-2 mx-2 navbar-start">
        <Link to={'/'} className={"flex gap-2"}>
          <img className={"h-8"} src={logo} alt={"FlagMonkez"}/> <span className={"text-lg font-semibold"}>flagmonkez</span>
        </Link>
      </div>
      <div className="hidden px-2 mx-2 navbar-center lg:flex">
        <div className="flex items-stretch">
          <Link to={'/'} className="btn btn-ghost btn-sm rounded-btn">
            Home
          </Link>
          <a rel={"noreferrer"} href={"https://flagmonkez.holaplex.com"} target={"_blank"} className="btn btn-ghost btn-sm rounded-btn">
            Buy Flagz
          </a>
        </div>
      </div>
      <div className="navbar-end">
        <div className="hidden md:flex">
          <select value={theme} onChange={selectTheme} className="select select-bordered w-full max-w-xs select-ghost">
            <option value={"dark"}>Night</option>
            <option value={"emerald"}>Emerald</option>
            <option value={"luxury"}>Banana</option>
            <option value={"cyberpunk"}>Cyberpunk</option>
            <option value={"wireframe"}>Wireframe</option>
          </select>
        </div>
        <div className="flex">
          {!wallet ?
            <ConnectButton label={"Connect"} />
            :
            // <Link to={'/dashboard'} className={"ml-4 btn btn-outline"}>
            //   {shortenAddress(wallet.publicKey.toString(), 4)}
            // </Link>
            <UserDropdown wallet={wallet} />
          }
        </div>
      </div>
    </div>
  )
}

export default Navbar;
