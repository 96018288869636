import Navbar from "../components/Navbar";
import React from "react";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <div>
      <div className="container mx-auto mt-4 px-2 md:px-4">
        <Navbar/>
      </div>
      <div className="container mx-auto p-2 px-2 md:px-4">
        <Outlet/>
      </div>
    </div>
  );
}

export default MainLayout;
