import React, { useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { getPhantomWallet, getSlopeWallet, getSolflareWallet } from '@solana/wallet-adapter-wallets';

import Home from './views/Home';
import NFTView from './views/NFTView';
import MainLayout from './layouts/MainLayout';
import { Dashboard } from './views/Dashboard';
import { SniperView } from './views/SniperView';
import DropListView from './views/DropListView';
import JupiterApp from './providers/JupiterApp';
import TokensListView from './views/TokensListView';
import AlphaHunterView from './views/AlphaHunterView';

require('@solana/wallet-adapter-react-ui/styles.css');

function App() {
  //const endpoint = useMemo(() => clusterApiUrl('mainnet-beta'), []);
  const rpcHost = 'https://purple-spring-forest.solana-mainnet.quiknode.pro/34f67187a25b5e66bad02b862c13de03acb0a8d1/';
  const endpoint = useMemo(() => process.env.NODE_ENV === 'production' ? rpcHost : 'https://api.metaplex.solana.com', []);
  const wallets = useMemo(() => [
    getPhantomWallet(),
    getSolflareWallet(),
    getSlopeWallet(),
  ], []);
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect={true}>
        <WalletModalProvider>
          <JupiterApp>
            <BrowserRouter>
              <Routes>
                <Route path={'/'} element={<MainLayout/>}>
                  <Route index element={<Home/>}/>
                  <Route path={':id'} element={<NFTView/>}/>
                  <Route path={'dashboard'} element={<Dashboard/>}/>
                  <Route path={'sniper'} element={<SniperView/>}/>
                  <Route path={'drops'} element={<DropListView/>}/>
                  <Route path={'tokens'} element={<TokensListView/>}/>
                  <Route path={'alpha-hunter'} element={<AlphaHunterView/>}/>
                </Route>
              </Routes>
            </BrowserRouter>
          </JupiterApp>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
