import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";

import { Project } from "../models/project";
import { ProjectsContext } from "../providers/ProjectsProvider";


const _fetchProjects = async (): Promise<Project[]> => {
  const resp = await axios.get<Project[]>('/projects/');
  return resp.data;
}

export const useProjects = () => {
  const [projects, setProjects] = useContext(ProjectsContext) as [Project[], Dispatch<SetStateAction<Project[]>>];
  const [cursor, setCursor] = useState(1);

  const fetchProjects = useCallback(async () => {
    console.log('fetching projects');
    const data = await _fetchProjects();
    setProjects(data);
  }, [setProjects])

  const upVote = async (id: number, owner: string) => {
    await axios.post(`/projects/${id}/upvote/`, {
      owner,
    });
    const newProjects = [...projects];
    const i = newProjects.findIndex((v) => v.id === id);
    newProjects[i].vote += 1;
    setProjects(newProjects);
  }

  const downVote = async (id: number, owner: string) => {
    await axios.post(`/projects/${id}/downvote/`, {
      owner,
    });
    const newProjects = [...projects];
    const i = newProjects.findIndex((v) => v.id === id);
    newProjects[i].vote -= 1;
    setProjects(newProjects);
  }

  const claim = async (id: number, address: string, owner: string, discord: string) => {
    await axios.post(`/projects/${id}/claim/`, {
      discord,
      wallet: address,
      holder: owner,
    })
  }

  return {
    cursor,
    downVote,
    projects,
    fetchProjects,
    setProjects,
    setCursor,
    upVote,
    claim,
  }
}
