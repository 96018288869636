import React, { useState } from "react";
import ProjectList from "../components/ProjectList";
import ProjectForm from "../components/ProjectForm";
import { ProjectsProvider } from "../providers/ProjectsProvider";

const DropListView = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <ProjectsProvider>
      <div className="sm:pt-4 pb-10 sm:pb-14 flex flex-wrap items-center">
        <div className="w-full flex-none text-center xl:w-auto xl:flex-auto mt-10">
          <h1
            className="font-display text-white text-3xl leading-9 font-semibold sm:text-4xl sm:leading-10">
            Upcoming whitelist drops
          </h1>
        </div>
      </div>
      <div className="flex justify-end mb-4">
        <button onClick={() => setOpenModal(true)} className={"btn btn-primary"}>
          Submit Drop
        </button>
        <ProjectForm openModal={openModal} setOpenModal={setOpenModal}/>
      </div>
      <ProjectList/>
    </ProjectsProvider>
  )
};

export default DropListView;
