import React from 'react';
import {Metadata} from '../models/metadata';
import MonkezCard from "./MonkezCard";

const MonkezList = (props: {
  items: Metadata[]
}) => {
  const sortedItems = props.items.sort((x, y) => y.id - x.id);
  return (
    <>
      <ul className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
        {sortedItems && sortedItems.map((item, idx) => (
          <li className={""} key={idx}>
            <MonkezCard item={item} />
          </li>
        ))}
      </ul>
    </>
  )
}

export default MonkezList;
